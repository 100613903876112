<!-- 注册 -->
<template>
  <div class="_cellphone">
    <div class="bese"></div>
    <div class="dingbu"></div>
    <div class="banxin">
      <!-- 板块 -->
      <div class="card">
        <div class="fontone">
          <div class="yiyou opacity">已有账号,点击绑定</div>
          <div>{{ "注册" }}</div>
          <div class="yiyou opacity">已有账号,点击绑定</div>
        </div>
        <div v-if="qie == 0">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="126px"
            class="demo-ruleForm"
          >
            <!-- 输入昵称 -->
            <el-form-item label="昵称：" prop="state2">
              <el-input
                class="inline-input"
                v-model="ruleForm.state2"
                placeholder="请输入昵称"
              >
              </el-input>
            </el-form-item>
            <!-- 用户名 -->
            <el-form-item label="用户名：" prop="username">
              <el-input
                class="inline-input"
                v-model="ruleForm.username"
                placeholder="请输入用户名"
              >
              </el-input>
            </el-form-item>
            <!-- 手机验证码 -->
            <el-form-item label="手机号：" prop="dianhua">
              <div class="yanzh">
                <el-input
                  class="inline-input"
                  v-model="ruleForm.dianhua"
                  placeholder="请输入手机号"
                >
                </el-input>
                <div class="flex1">
                  <div
                    v-if="timerNumber == 0"
                    class="fonttwo"
                    @click="SendingNumber"
                  >
                    {{ "获取验证码" }}
                  </div>
                  <div v-else class="fonttwo">{{ timerNumber }}</div>
                </div>
              </div>
            </el-form-item>
            <!-- 验证码 -->
            <el-form-item label="验证码：" prop="yanzhneg">
              <el-input
                class="inline-input"
                v-model="ruleForm.yanzhneg"
                placeholder="请输入验证码"
              >
              </el-input>
            </el-form-item>
            <!-- 密保问题 -->
            <el-form-item label="密保问题：" prop="value">
              <el-select
                v-model="ruleForm.value"
                placeholder="请选择密保问题"
                @change="huode"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 密保答案 -->
            <el-form-item label="密保答案：" prop="daan">
              <el-input
                class="inline-input"
                v-model="ruleForm.daan"
                placeholder="请输入答案"
              >
              </el-input>
            </el-form-item>
            <!-- 邮箱 -->
            <el-form-item label="邮箱：" prop="youxiang">
              <el-input
                class="inline-input"
                v-model="ruleForm.youxiang"
                placeholder="请输入邮箱"
              >
              </el-input>
            </el-form-item>
            <!-- 密码 -->
            <el-form-item label="登录密码：" prop="mima">
              <el-input
                class="inline-input"
                show-password
                v-model="ruleForm.mima"
                placeholder="6-20个字符，区分大小写"
              >
              </el-input>
            </el-form-item>
            <!-- 校验 -->
            <div class="jiaoyan">
              <div class="flex align-items">
                <i
                  :class="{ acto: pnum == 1 }"
                  class="el-icon-success ifont"
                ></i>
                <span :class="{ acttwo: pnum == 1 }" class="pafont"
                  >密码由6-20位组成</span
                >
              </div>
              <div class="flex" style="margin-top: 10px">
                <i
                  :class="{ actthree: pnums == 1 }"
                  class="el-icon-success ifont"
                ></i>
                <span :class="{ actfour: pnums == 1 }" class="pafont"
                  >密码满足字母或特殊字符串的其中一种(特殊字符包括
                  .!@#%)</span
                >
              </div>
            </div>
            <!-- 确认密码 -->
            <el-form-item label="确认密码：" prop="qmima">
              <el-input
                class="inline-input"
                show-password
                v-model="ruleForm.qmima"
                placeholder="请确认登录密码"
              >
              </el-input>
            </el-form-item>
          </el-form>

          <div class="anniu">
            <div class="btn" @click="zhuce('ruleForm')">{{ "提交" }}</div>
          </div>
          <div class="fanhui" @click="fanhui">{{ "已有帐号？点此登录" }}</div>
          <!-- 11 -->
        </div>
        <div v-else></div>
      </div>
    </div>
    <!-- 透明信息弹框 -->
    <div class="tan" v-if="tannum != 0">
      <div class="juz">
        <div class="tishi">注册成功!</div>
        <div class="tishi mt10">{{ tnum }}秒后返回登录页</div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/login.js";
import Cookies from "js-cookie";
var timer; // 计时器
export default {
  data() {
    return {
      pnum: 0, //密码校验
      pnums: 0, //密码校验
      platform: "", //平台
      loading: null,
      tannum: 0,
      tnum: 0,
      timerNumber: 0, //计时
      qie: 0,
      options: [
        {
          value: "1",
          label: "母亲的名字",
        },
        {
          value: "2",
          label: "爷爷的名字",
        },
        {
          value: "3",
          label: "父亲的手机号码",
        },
        {
          value: "4",
          label: "您其中一位老师的名字",
        },
        {
          value: "5",
          label: "您个人计算机的型号",
        },
        {
          value: "6",
          label: "您最喜欢的餐馆名称",
        },
        {
          value: "7",
          label: "驾驶执照最后四位数字",
        },
      ],
      ruleForm: {
        state2: "", //昵称
        username: "", //用户名
        dianhua: "", //手机号
        yanzhneg: "", //验证码
        daan: "", //密保答案
        youxiang: "", //邮箱
        mima: "", //密码
        qmima: "", //确认密码
        value: "",
      },
      rules: {
        state2: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "昵称长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "用户民长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        yanzhneg: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        dianhua: [
          { required: true, message: "请填写联系人电话", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        value: [{ required: true, message: "请选择问题" }],
        daan: [
          { required: true, message: "请回答您的问题", trigger: "blur" },
          {
            min: 2,
            max: 20,
            message: "答案长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        youxiang: [
          {
            pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/,
            message: "请输入正确的邮箱号",
          },
        ],
        mima: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "密码长度在 6 到 20 个字符(区别大小写)",
            trigger: "blur",
          },
          // {
          //   pattern:
          //     /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/,
          //   message: "密码未符合规则",
          //   trigger: "blur",
          // },
          {
            validator: (rule, value, callback) => {
              let a = /[A-Za-z.!@#%]/.test(value)
              if (a === false) {
                callback(new Error("密码未符合规则"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },

        ],
        qmima: [
          { required: true, message: "请再次输入密码", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("请再次输入密码"));
              } else if (value !== this.ruleForm.mima) {
                callback(new Error("两次输入密码不一致"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    // let aa = this.yonghu();
    // if (aa == true) {
    //   this.$message({
    //     message: "您已经处于登录状态!",
    //     type: "warning",
    //   });
    //   this.jump("/", this.$store.state.loginMessage);
    //   return;
    // }
    // this.ruleForm.state2 = this.$store.state.openname;
    let q = {};
    // 获取来自网址连接的信息
    let test = window.location.href;
    test.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (q[k] = v));
    this.$store.state.cert = q.cert;
    this.platform = q.platform;
    this.ruleForm.state2 = localStorage.getItem("openname");
    // console.log(this.$store.state.cert,this.platform,this.ruleForm.state2,'啥就看看');
  },
  watch: {
    "ruleForm.mima": function (vla, old) {
      if (vla.length < 6) {
        this.pnum = 0;
      }
      if (vla.length >= 6) {
        this.pnum = 1;
      }
      if (vla.length > 20) {
        this.pnum = 0;
      }
      const check = /[A-Za-z.!@#%]/
        // /^(?=.{6})(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)(?=.*?[.!@#%]).*$/;
      if (check.test(vla)) {
        this.pnums = 1;
      } else {
        this.pnums = 0;
      }
    },
  },
  methods: {
    // 测试有没有处于登录状态
    async yonghu() {
      let a = false;
      let obj = await API.userHomepage().then((res) => {
        if (res.code == 1) {
          a = true;
        }
      });
      return a;
    },
    huode(e) {
      this.value = e;
    },
    async zhu() {
      // state2: "", //昵称
      //   dianhua: "", //手机号
      //   yanzhneg: "", //验证码
      //   daan: "", //密保答案
      //   youxiang: "", //邮箱
      //   mima: "", //密码
      //   qmima: "", //确认密码
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.register({
        mobile: this.ruleForm.dianhua,
        nickname: this.ruleForm.state2,
        byname: this.ruleForm.username,
        captcha: this.ruleForm.yanzhneg,
        email: this.ruleForm.youxiang,
        question: this.ruleForm.value,
        answer: this.ruleForm.daan,
        gender: 0,
        password: this.ruleForm.qmima,
        cert: this.$store.state.cert,
        platform: localStorage.getItem("dr"),
      }).then((res) => {
        if (res.code == 1) {
          sessionStorage.removeItem("dr");
          this.tannum = 1;
          this.tnum = 3;
          this.daoji();
          this.loading.close();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.loading.close();
        }
      });
    },
    daoji() {
      timer = setTimeout(() => {
        if (this.tnum == 0) {
          this.tannum = 0;
          // 先关闭当前页面
          // window.close();
          // 如果不行就返回
          this.fanhui();
          return;
        }
        this.tnum--;
        this.daoji();
      }, 1000);
    },
    zhuce(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.zhu();
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    fanhui() {
      this.tannum = 0;
      this.$router.push("/login");
    },
    // 对手机号的正则验证,通过下一步
    async SendingNumber() {
      if (!/^1[3456789]\d{9}$/.test(this.ruleForm.dianhua)) {
        this.$message({
          message: "您的手机号码输入有误,请您重新输入!",
          type: "warning",
        });
        return;
      }
      if (this.ruleForm.dianhua == "") {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "Loading",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });
      let res = await API.sms({
        mobile: this.ruleForm.dianhua, //手机号
        event: "register", //'手机验证码登录'
      }).then((res) => {
        if (res.code == 1) {
          this.$message({
            message: "验证码已发送至您的手机,请注意查看!",
            type: "success",
          });
          this.timerNumber = 60;
          this.countDown();
          this.loading.close();
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 计时器倒计时
    countDown() {
      if (this.timerNumber != 0) {
        this.timer = setTimeout(() => {
          this.timerNumber--;
          this.countDown();
        }, 1000);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(timer);
    sessionStorage.removeItem("openname");
  },
};
</script>

<style scoped lang="scss">
.bese {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #eff5ff !important;
  z-index: -1;
}
._cellphone {
  width: 100vw;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.dingbu {
  width: 100%;
  height: 127px;
  background: url("../../assets/img/login/ding.png");
  background-size: 100% 100%;
}
.banxin {
  width: 1200px;
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.card {
  width: 660px;
  background: #ffffff;
  box-shadow: 3px 8px 11px 0 rgba(49, 60, 87, 0.12);
  border-radius: 20px;
  padding: 29.03px 101px 17px 69px;
  box-sizing: border-box;
  margin-bottom: 91px;
}
.demo-ruleForm {
  width: 100%;
}
::v-deep(.el-form-item__label) {
  font-family: PingFangSC-Regular;
  font-size: 18px !important;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400 !important;
  white-space: nowrap !important;
}
::v-deep(.el-input__inner) {
  width: 100% !important;
  height: 48px !important;
  background: #f1f1f1 !important;
  border-radius: 2px !important;
  border: 0px solid;
  font-family: PingFangSC-Regular;
  font-size: 18px !important;
  color: #191a1c !important;
  letter-spacing: 0;
  font-weight: 400;
  &::placeholder {
    font-family: PingFangSC-Regular;
    font-size: 18px !important;
    color: #484c51 !important;
    letter-spacing: 0;
    font-weight: 400 !important;
  }
}
.yanzh {
  display: flex;
  align-items: center;
  flex: 1;
  background: #f1f1f1 !important;
  border-radius: 2px !important;
  padding: 0 16px 0 0;
  box-sizing: border-box;
}
.fontone {
  font-family: PingFangSC-Medium;
  font-size: 38px;
  color: #131313;
  letter-spacing: 0;
  text-align: center;
  font-weight: 500;
  margin-bottom: 31.76px;
  display: flex;
  justify-content: space-between;
}
.fonttwo {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #2c74c6;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
}
::v-deep(.el-select) {
  width: 100%;
}
::v-deep(.el-cascader) {
  .el-input__icon:before {
    content: "\e790";
    font-size: 18px !important;
    color: #6c7075 !important;
  }
}

::v-deep(.el-select) {
  .el-select__caret:before {
    content: "\e790";
    font-size: 18px !important;
    color: #6c7075 !important;
  }
}
.anniu {
  margin-top: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 46.52px;
}
.btn {
  width: 327px;
  height: 48px;
  background: #2c74c6;
  border-radius: 2px;
  text-align: center;
  line-height: 48px;
  font-family: PingFangSC-Medium;
  font-size: 20px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  cursor: pointer;
}
.fanhui {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #296aef;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-bottom: 17px;
  cursor: pointer;
}
.tan {
  position: fixed;
  width: 200px;
  height: 100px;
  top: calc(50% - 100px);
  left: calc(50% - 50px);
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tishi {
  font-family: PingFangSC-Regular;
  font-size: 18px !important;
  color: #fff;
  font-weight: 400 !important;
}
.mt10 {
  margin-top: 10px;
}
.juz {
  text-align: center;
}
.yiyou {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #18191a;
  letter-spacing: 0;
  line-height: 27px;
  font-weight: 400;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0 5px 10px;
  box-sizing: border-box;
  color: #3a99cf;
}
.jiaoyan {
  margin-top: 22px;
  margin-left: calc(90px + 44px);
  margin-bottom: 22px;
  .ifont {
    font-size: 20px;
    color: #888888;
  }
  .pafont {
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #888888;
    margin-left: 5px;
  }
  .acto {
    color: #339933 !important;
  }

  .acttwo {
    color: #000 !important;
  }

  .actthree {
    color: #339933 !important;
  }

  .actfour {
    color: #000 !important;
  }
  .acttfive {
    color: #339933 !important;
  }
  .actsix {
    color: #000 !important;
  }
}
</style>